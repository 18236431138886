import { antDesignDecimalValueValidator } from '../../../helper';
import { HsnFormItem } from 'shared/components/HsnFormItem';

export const CatalogueFormFields = [
	{
		name: 'productTitle',
		id: 'productTitle',
		label: 'Item Name',
		required: true,
		placeholder: 'Enter Item Name'
	},
	{
		name: 'variantTitle',
		id: 'variantTitle',
		label: 'Item Variant Name',
		required: true,
		placeholder: 'Enter Item Variant Name'
	},
	// {
	// 	name: 'sku',
	// 	id: 'sku',
	// 	label: 'SKU ID',
	// 	required: true,
	// 	placeholder: 'Enter SKU ID',
	// 	rules: [
	// 		{ pattern: /^((?![ ]).)*$/, message: 'SKU ID must not contain space' },
	// 		{ pattern: /^((?![\-]).)*$/, message: 'SKU ID must not contain hyphen' },
	// 		{ pattern: /^((?![()]).)*$/, message: 'SKU ID must not contain brackets' }
	// 	]
	// },
	{
		name: 'hsnCode',
		id: 'hsnCode',
		label: 'HSN Code',
		fieldType: 'custom',
		component: <HsnFormItem showTitle={false} isLarge={false} />
	},
	{
		name: 'category',
		id: 'category',
		label: 'Category',
		required: true,
		placeholder: 'Enter Category'
	},
	{
		name: 'handle',
		id: 'handle',
		label: 'Handle',
		required: true,
		placeholder: 'Enter Handle'
	},
	{
		name: 'barcode',
		id: 'barcode',
		label: 'Barcode',
		required: false,
		placeholder: 'Enter Barcode'
	},
	{
		name: 'active',
		id: 'active',
		fieldType: 'select',
		label: 'Status',
		required: true,
		placeholder: 'Select Status',
		options: [
			{
				value: 1,
				label: 'Active'
			},
			{
				value: 0,
				label: 'InActive'
			}
		]
	},
	{
		name: 'price',
		id: 'price',
		label: 'Price (inclusive of GST)',
		required: true,
		placeholder: 'Enter Price'
	},
	{
		name: 'length',
		id: 'length',
		label: 'Length(cm)',
		required: true,
		placeholder: 'Enter Length'
	},
	{
		name: 'breadth',
		id: 'breadth',
		label: 'Breadth(cm)',
		required: true,
		placeholder: 'Enter Breadth'
	},
	{
		name: 'height',
		id: 'height',
		label: 'Height(cm)',
		required: true,
		placeholder: 'Enter Height'
	},
	{
		name: 'margin',
		id: 'margin',
		label: 'Margin (%)',
		required: false,
		placeholder: 'Enter margin %'
	},
	{
		name: 'distributorMargin',
		id: 'distributorMargin',
		label: 'Distributor Margin (%)',
		required: false,
		placeholder: 'Enter Distributor margin %'
	},
	{
		name: 'basePrice',
		id: 'basePrice',
		label: 'Base Price',
		required: false,
		placeholder: 'Enter Base Price'
	},
	{
		name: 'd2rCategory',
		id: 'd2rCategory',
		label: 'D2R Category',
		required: false,
		placeholder: 'Select D2R Category',
		fieldType: 'select',
		options: [
			{
				value: 'CHIPS',
				label: 'CHIPS'
			},
			{
				value: 'BISCUIT',
				label: 'BISCUIT'
			},
			{
				value: 'CORN_PUFF',
				label: 'CORN PUFF'
			},
			{
				value: 'CHOCOLATE',
				label: 'CHOCOLATE'
			}
		]
	},
	{
		name: 'weight',
		id: 'weight',
		label: 'Weight(gm)',
		required: true,
		placeholder: 'Enter Weight'
	},
	{
		name: 'sgst',
		id: 'sgst',
		label: 'SGST (%)',
		required: true,
		placeholder: 'Enter SGST (%)',
		rules: antDesignDecimalValueValidator
	},
	{
		name: 'cgst',
		id: 'cgst',
		label: 'CGST (%)',
		required: true,
		placeholder: 'Enter CGST (%)',
		rules: antDesignDecimalValueValidator
	},
	{
		name: 'igst',
		id: 'igst',
		label: 'IGST (%)',
		required: true,
		placeholder: 'Enter IGST (%)',
		rules: antDesignDecimalValueValidator
	},
	{
		name: 'utgst',
		id: 'utgst',
		label: 'UTGST (%)',
		required: true,
		placeholder: 'Enter UTGST (%)',
		rules: antDesignDecimalValueValidator
	},
	{
		name: 'imageUrls',
		id: 'imageUrls',
		label: 'Product Image',
		required: true,
		fieldType: 'image',
		placeholder: 'Pick Product Image'
	}
];
