import { Alert, Button, Divider, Form, Input, Modal, notification, Tooltip } from 'antd';
import { useState } from 'react';
import { baseApi } from '../../../../api';
import errorHandler from '../../../../utils/errorHandler';
import { getWSINMapFormat } from '../../../Catalog/constants';

interface ICreditDebitNoteModal {
	order: any;
	setIsCreditDebitNodeModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
	setReloadOrder: React.Dispatch<React.SetStateAction<number>>;
}

export const CreditDebitNoteModal = (props: ICreditDebitNoteModal) => {
	const { order, setIsCreditDebitNodeModalVisible, setReloadOrder } = props;

	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();

	const handleOnFinish = async (value: any) => {
		try {
			setLoading(true);
			const { data } = await baseApi.post(`/wms/inventory/credit-debit/note/${order?.id}`, value);
			data?.status &&
				notification.success({
					message: data?.message
				});
			setIsCreditDebitNodeModalVisible(false);
			setReloadOrder((e) => e + 1);
		} catch (err) {
			errorHandler(err);
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<Modal
				title="Fill Credit Debit Note details"
				visible={true}
				onCancel={() => setIsCreditDebitNodeModalVisible(false)}
				footer={[]}
				width={900}
			>
				<Form form={form} size="small" layout="vertical" onFinish={handleOnFinish}>
					<Alert message="Add Damaged / Expired Products (If any)" type="warning" />
					<table style={{ width: '100%', margin: '15px', fontSize: '12px' }}>
						<tr>
							{['Title', 'SKU', 'WSIN', 'Total Returns', 'Expired', 'Damaged'].map((e) => (
								<th style={{ width: '95px' }}>{e}</th>
							))}
						</tr>

						{order?.items.map((item: any) => (
							<tr key={item.id} style={{ verticalAlign: 'inherit' }}>
								<td>
									<Tooltip placement="top" title={item.title}>
										<h6 style={{ fontSize: '1.5vmin', textOverflow: 'ellipsis', width: '195px' }}>{item.title}</h6>
									</Tooltip>
								</td>
								<td>
									<h6 style={{ fontSize: '1.5vmin', width: '195px' }}>{item.sku}</h6>
								</td>
								<td>
									<h6 style={{ fontSize: '1.5vmin', width: '195px' }}>{getWSINMapFormat(item?.wsinMapping || {})}</h6>
								</td>
								<td>
									<Form.Item name={[item.id, 'credit']} style={{ width: '85%' }}>
										<Input
											type="number"
											size="large"
											placeholder={`Out of ${item.quantity * 1}`}
											suffix={` / ${item.quantity * 1}`}
											max={item.quantity * 1}
											min={0}
											value={0}
										/>
									</Form.Item>
								</td>
								<td>
									<Form.Item name={[item.id, 'expired']} style={{ width: '85%' }}>
										<Input
											type="number"
											size="large"
											placeholder={`0`}
											suffix={` / ${item.quantity * 1}`}
											max={item.quantity * 1}
											min={0}
											value={0}
										/>
									</Form.Item>
								</td>
								<td>
									<Form.Item name={[item.id, 'damaged']} style={{ width: '85%' }}>
										<Input
											type="number"
											size="large"
											placeholder={`0`}
											suffix={` / ${item.quantity * 1}`}
											max={item.quantity * 1}
											min={0}
											value={0}
										/>
									</Form.Item>
								</td>
								<Divider />
							</tr>
						))}
					</table>
					<Form.Item>
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<Button
								type="primary"
								size="large"
								block
								htmlType="submit"
								loading={loading}
								style={{ position: 'sticky', top: '0px', width: '20rem' }}
							>
								Generate
							</Button>
						</div>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};
